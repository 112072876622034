<template>
  <v-expansion-panels v-model="panel" class="mb-6">
    <v-expansion-panel>
      <v-expansion-panel-header> </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="ml-3">
          <v-col cols="4">
            <v-autocomplete
              outlined
              v-model="projects"
              :items="projectsList"
              :menu-props="{ maxHeight: '400', zIndex: 13 }"
              label="Projet"
              item-text="name"
              item-value="id"
              multiple
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              outlined
              v-model="eventTypes"
              :items="eventTypesList"
              :menu-props="{ maxHeight: '400', zIndex: 13 }"
              label="Type d'événement"
              item-text="name"
              item-value="id"
              multiple
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              outlined
              v-model="eventCategories"
              :items="eventCategoriesList"
              :menu-props="{ maxHeight: '400', zIndex: 13 }"
              label="Catégorie d'événement"
              item-text="name"
              item-value="id"
              multiple
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <date-filter
              store="rmra"
              range="7"
              periodType="past-only"
              :max="maxDate"
            ></date-filter>
          </v-col>
          <v-spacer />
          <v-col cols="2" class="d-flex">
            <apply-filters
              title="Appliquer"
              :disabled="disabled"
              styles="height: 56px !important; width: 100% !important;"
              classList=""
            />
          </v-col>
        </v-row>
        <v-progress-linear
          v-if="progress > 0 && progress < 100"
          :value="progress"
          color="#10396f"
          background-color="#ebebf3"
        ></v-progress-linear>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateFilter from "@/components/common/filters/DateFilter.vue";
import useAxios from "@/hooks/useAxios";
import debounce from "lodash/debounce";
import { defaultPeriodType } from "@/components/audience/perf-per-article/utils/constants";

export default {
  name: "Filters",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ApplyFilters,
    DateFilter,
  },
  async created() {
    await this.$store.dispatch("rmra/updateProjectsList");
    await this.$store.dispatch("rmra/updateEventCategoriesList");
  },
  data() {
    return {
      disabled: false,
      loading: true,
      value: 25,
      panel: 0,
    };
  },
  setup() {
    const { isLoading } = useAxios();
    const { axiosGet } = useAxios();
    return {
      isLoading,
      axiosGet,
    };
  },
  methods: {
    debouncedUpdateDisabled: debounce(async function () {
      this.updateDisabled();
    }, 500),
    updateDisabled() {
      // this.disabled = this.eventTypes.length === 0 || this.isLoading;
      this.disabled = this.isLoading;
    },
    validatePeriodType(periodType) {
      const allowedValues = this.periodTypeList.map((entry) => entry.id);

      return allowedValues.includes(periodType)
        ? periodType
        : defaultPeriodType;
    },
  },
  computed: {
    maxDate() {
      const today = new Date();
      return today.toISOString().split("T")[0];
    },
    eventTypesList() {
      return this.$store.getters["rmra/getEventTypesList"];
    },
    eventTypes: {
      get() {
        return this.$store.getters["rmra/getEventTypes"];
      },
      set(value) {
        this.$store.commit("rmra/updateEventTypes", value);
      },
    },
    projectsList() {
      return this.$store.getters["rmra/getProjectsList"];
    },
    projects: {
      get() {
        return this.$store.getters["rmra/getProjects"];
      },
      set(value) {
        this.$store.commit("rmra/updateProjects", value);
      },
    },
    eventCategoriesList() {
      return this.$store.getters["rmra/getEventCategoriesList"];
    },
    eventCategories: {
      get() {
        return this.$store.getters["rmra/getEventCategories"];
      },
      set(value) {
        this.$store.commit("rmra/updateEventCategories", value);
      },
    },
  },
  watch: {
    isLoading() {
      this.debouncedUpdateDisabled();
    },
    eventTypes() {
      this.debouncedUpdateDisabled();
    },
    async projects() {
      await this.$store.dispatch("rmra/updateEventTypesList", this.projects);
    },
  },
};
</script>

<style>
.v-menu__content {
  z-index: 13 !important;
}
</style>
