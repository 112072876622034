<template>
  <pie-chart
    title="Répartition de l'utilisation"
    subTitle="des outils et modules"
    :series="series"
    :labels="labels"
    :loading="loading"
    :colors="colors"
  />
</template>

<script>
import PieChart from "@/components/common/charts/PieChart.vue";
import { chartColors } from "@/utils/constants";

export default {
  name: "UsageDistribution",
  components: { PieChart },
  data() {
    return {
      series: [],
      labels: [],
      colors: chartColors,
    };
  },
  methods: {
    formatData(data, n) {
      const groupedData = data.reduce((acc, entry) => {
        const type = entry.type;
        acc[type] = (acc[type] || 0) + 1;
        return acc;
      }, {});
      const sortedEntries = Object.entries(groupedData).sort(
        (a, b) => b[1] - a[1]
      );
      const topEntries = sortedEntries.slice(0, n);
      const othersCount = sortedEntries
        .slice(n)
        .reduce((sum, entry) => sum + entry[1], 0);

      this.series = [...topEntries.map((entry) => entry[1]), othersCount];
      this.labels = [...topEntries.map((entry) => entry[0]), "Autres"];
    },
  },
  computed: {
    loading() {
      return this.$store.getters["common/getLoading"];
    },
    events() {
      return this.$store.getters["rmra/getEvents"];
    },
    filteredEvents() {
      return this.$store.getters["rmra/getFilteredEvents"];
    },
  },
  watch: {
    events: {
      handler: function (val) {
        this.items = this.formatData(val, 6);
      },
      deep: true,
    },
    filteredEvents: {
      handler: function (val) {
        this.items = this.formatData(val, 6);
      },
      deep: true,
    },
  },
};
</script>
