<template>
  <v-card class="mb-6 pa-2">
    <v-card-title>
      <h3 class="text-h6">Répartition de l'utilisation</h3>
    </v-card-title>
    <v-card-subtitle>des outils et modules</v-card-subtitle>
    <v-data-table
      locale="fr-FR"
      :headers="headers"
      :items="items"
      :sort-by="'updated'"
      :sort-desc="true"
      :loading="loading"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
      }"
    >
      <template v-slot:item.time="{ item }">
        {{ getTimeStr(new Date(item.date)) }}
      </template>

      <template v-slot:item.date="{ item }">
        {{ getDateStr(new Date(item.date)) }}
      </template>
    </v-data-table>
  </v-card>
</template>
<style scoped></style>
<script>
import { mdiPencil, mdiTrashCan } from "@mdi/js";
import { getDateStr, getTimeStr } from "@/utils/dates";
import DotMenu from "@/components/common/menus/DotMenu";

export default {
  name: "EventsTable",
  components: {
    DotMenu,
  },
  data() {
    return {
      search: "",
      csvData: "",
      dialogModel: {
        dialog: false,
        id: -1,
        entryName: "",
      },
      headers: [
        {
          text: "Type",
          align: "start",
          value: "type",
        },
        {
          text: "Nombre",
          value: "count",
        },
      ],
      icons: {
        mdiPencil,
        mdiTrashCan,
      },
      items: [],
    };
  },
  methods: {
    getTimeStr,
    getDateStr,
    formatData(data) {
      const groupedData = data.reduce((acc, entry) => {
        const type = entry.type;
        acc[type] = (acc[type] || 0) + 1;
        return acc;
      }, {});
      const sortedEntries = Object.entries(groupedData).sort(
        (a, b) => b[1] - a[1]
      );

      return sortedEntries.map((entry) => {
        return {
          type: entry[0],
          count: entry[1],
        };
      });
    },
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters["common/getLoading"];
      },
      set(val) {
        this.$store.dispatch("common/updateLoading", val);
      },
    },
    events() {
      return this.$store.getters["rmra/getEvents"];
    },
    filteredEvents() {
      return this.$store.getters["rmra/getFilteredEvents"];
    },
    dates() {
      return this.$store.getters["rmra/getDates"];
    },
  },
  watch: {
    filteredEvents: {
      handler: function (val) {
        this.items = this.formatData(val);
      },
      deep: true,
    },
    events: {
      handler: function (val) {
        this.items = this.formatData(val);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-with-ga-id {
  max-width: 300px;
}
.above-table {
  width: 100%;
}
.search-by-site-id {
  width: 100px;
}
</style>
