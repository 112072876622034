<template>
  <div>
    <p class="text-2xl mb-6">Liste des Évenements</p>
    <filters></filters>
    <v-row>
      <v-col cols="12" md="6">
        <historical-events-graph></historical-events-graph>
      </v-col>
      <v-col cols="12" md="6">
        <events-table></events-table>
      </v-col>
      <v-col cols="12" md="6">
        <events-distribution-table></events-distribution-table>
      </v-col>
      <v-col cols="12" md="6">
        <usage-distribution></usage-distribution>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiPlusBox } from "@mdi/js";
import EventsTable from "@/components/rmr-analytics/tables/EventsTable.vue";
import Filters from "@/components/rmr-analytics/events-list/filters/Filters.vue";
import HistoricalEventsGraph from "@/components/rmr-analytics/events-list/graphs/HistoricalEventsGraph.vue";
import UsageDistribution from "@/components/rmr-analytics/events-list/graphs/UsageDistribution.vue";
import EventsDistributionTable from "@/components/rmr-analytics/tables/EventsDistributionTable.vue";
import axios from "@axios";
import debounce from "lodash/debounce";


export default {
  name: "EventsList",
  components: {
    EventsTable,
    Filters,
    HistoricalEventsGraph,
    UsageDistribution,
    EventsDistributionTable,
  },
  created() {},
  data() {
    return {
      icons: {
        mdiPlusBox,
      },
    };
  },
  methods: {
    debouncedUpdateData: debounce(async function () {
      await this.updateData();
    }, 500),
    async updateData() {
      const params = {};
      this.dates.sort();
      params.start_date = this.dates[0];
      params.end_date = this.dates[1];
      if (this.projects.length > 0) {
        params.projects = this.projects.join(",");
      }
      if (this.eventTypes.length > 0) {
        params.event_types = this.eventTypes.join(",");
      }
      if (this.eventCategories.length > 0) {
        params.event_categories = this.eventCategories.join(",");
      }
      this.loading = true;
      try {
        const { data } = await axios.get("/rmra-events/", { params });
        this.items = data.items;
        this.$store.dispatch("rmra/updateEvents", data.items);
        this.$store.dispatch("common/updateApplyFilters", false);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    loading: {
      get() {
        return this.$store.getters["common/getLoading"];
      },
      set(val) {
        this.$store.dispatch("common/updateLoading", val);
      },
    },
    dates() {
      return this.$store.getters["rmra/getDates"];
    },
    projects() {
      return this.$store.getters["rmra/getProjects"];
    },
    eventTypes() {
      return this.$store.getters["rmra/getEventTypes"];
    },
    eventCategories() {
      return this.$store.getters["rmra/getEventCategories"];
    },
  },
  watch: {
    async applyFilters(val) {
      // doing a single query for all components
      if (val) {
        await this.debouncedUpdateData();
      }
    },
  },
};
</script>
<style scoped></style>
