var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-6 pa-2"},[_c('v-card-title',[_c('v-row',[_c('h3',{staticClass:"text-h6"},[_vm._v("Historique des événements")]),_c('v-spacer'),_c('dot-menu',{attrs:{"isLoading":_vm.loading,"csvButton":{
          csvData: _vm.csvData,
          filename: ("export-rmra-" + (this.dates[0]) + "-" + (this.dates[1]) + ".csv"),
        }},on:{"exportCsv":_vm.exportCSV}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"locale":"fr-FR","headers":_vm.headers,"items":_vm.items,"sort-by":'updated',"sort-desc":true,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 25, 50, 100],
    }},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTimeStr(new Date(item.date)))+" ")]}},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateStr(new Date(item.date)))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }